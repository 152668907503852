import ENV from 'environment'

export enum WomenClothing {
  ACTIVEWEAR = 'Activewear',
  CARDIGANS_SWEATERS = 'Cardigans & Sweaters',
  COATS_BLAZERS_JACKETS = 'Coats/Blazers/Jackets',
  DRESSES = 'Dresses',
  JEANS = 'Jeans',
  JUMPSUITS_ROMPERS = 'Jumpsuits & Rompers',
  PANTS = 'Pants',
  SHORTS_SKORTS = 'Shorts & Skorts',
  SKIRTS = 'Skirts',
  TOPS = 'Tops',
}

export enum MenClothing {
  ACTIVEWEAR = 'Activewear',
  COATS_BLAZERS_JACKETS = 'Coats/Blazers/Jackets',
  JEANS = 'Jeans',
  PANTS = 'Pants',
  SHIRTS = 'Shirts',
  SHORTS = 'Shorts',
  SWEATERS = 'Sweaters',
  T_SHIRTS = 'T-Shirts',
}

export enum ClothingCategory {
  WOMEN = "Women's Clothing",
  MEN = "Men's Clothing",
}

export namespace ClothingCategoryUtil {
  export const clothingTypes = (clothingCategory: ClothingCategory) => {
    switch (clothingCategory) {
      case ClothingCategory.WOMEN:
        return WomenClothing
      case ClothingCategory.MEN:
        return MenClothing
    }
  }

  export const skuCode = (clothingCategory: ClothingCategory) => {
    switch (clothingCategory) {
      case ClothingCategory.WOMEN:
        return 'W'
      case ClothingCategory.MEN:
        return 'M'
    }
  }
}

// export namespace WomenClothingUtil {
//   export const skuCode = (clothingType: WomenClothing) => {
//     switch (clothingType) {
//       case WomenClothing.DRESSES:
//         return 'DRS'
//       case WomenClothing.TOPS:
//         return 'TOP'
//       case WomenClothing.SWEATERS:
//         return 'SWR'
//       case WomenClothing.COATS_AND_JACKETS:
//         return 'CAJ'
//       case WomenClothing.JEANS:
//         return 'JEN'
//       case WomenClothing.PANTS:
//         return 'PAT'
//       case WomenClothing.SKIRTS:
//         return 'SKT'
//       case WomenClothing.SHORTS:
//         return 'SHT'
//       case WomenClothing.ACTIVEWEAR:
//         return 'ACR'
//     }
//   }
// }

// export namespace MenClothingUtil {
//   export const skuCode = (clothingType: MenClothing) => {
//     switch (clothingType) {
//       case MenClothing.T_SHIRTS:
//         return 'TST'
//       case MenClothing.SHIRTS:
//         return 'SHT'
//       case MenClothing.POLOS:
//         return 'POS'
//       case MenClothing.PANTS:
//         return 'PAT'
//       case MenClothing.SHORTS:
//         return 'SHO'
//       case MenClothing.JEANS:
//         return 'JEN'
//       case MenClothing.OUTERWEAR:
//         return 'OUR'
//       case MenClothing.ACTIVEWEAR:
//         return 'ACR'
//     }
//   }
// }

export enum Condition {
  NEW_WITH_TAGS = 'New With Tags',
  LIKE_NEW = 'Like New',
  GENTLY_USED = 'Gently Used',
  SIGNS_OF_WEAR = 'Signs of Wear',
}

interface ObjectId {
  $oid: string
}

export interface ClothingRejectedItem {
  category: ClothingCategory
  type: MenClothing | WomenClothing
  brand: string
  staff: string
  timestamp_created: Date
}

export class ClothingRejectedItem {
  static fromJson(json: Object) {
    const clothingItem = Object.assign(new ClothingRejectedItem(), json)
    clothingItem.timestamp_created = new Date(clothingItem.timestamp_created)
    return clothingItem
  }
}

export interface ClothingItem extends ClothingRejectedItem {
  condition: Condition
}

export class ClothingItem extends ClothingRejectedItem {
  static fromJson(json: Object) {
    const clothingItem = Object.assign(new ClothingItem(), super.fromJson(json))
    return clothingItem
  }
}

export interface ClothingItemWithPrice extends ClothingItem {
  crediting_amount: number
  sale_price: number
}

export class ClothingItemWithPrice extends ClothingItem {
  static fromJson(json: Object) {
    const clothingItem = Object.assign(
      new ClothingItemWithPrice(),
      super.fromJson(json)
    )
    return clothingItem
  }
}

export interface ClothingItemWithPriceAndSku extends ClothingItemWithPrice {
  sku: string
}

export class ClothingItemWithPriceAndSku extends ClothingItemWithPrice {
  static fromJson(json: Object) {
    const clothingItem = Object.assign(
      new ClothingItemWithPriceAndSku(),
      super.fromJson(json)
    )
    return clothingItem
  }
}

export enum CreditingType {
  TRADE_IN = 'Trade-In',
  DONATION = 'Donation',
}

interface ClothingDataInput {
  crediting_type: CreditingType
  customer_first_name: string
  customer_email: string
  collection_date: Date
  clothes: Array<ClothingItemWithPriceAndSku>
  rejected_items: Array<ClothingRejectedItem>
  staff: Array<string>
  /** If merged, it will always be the earliest timestamp. */
  timestamp_created: Date
}

interface ClothingData extends ClothingDataInput {
  is_submitted: boolean
  is_partial_upload: boolean
  is_approved: boolean
  is_gift_card_issued: boolean
  is_email_sent: boolean
  has_email_error: boolean
  /** If merged, it will be the latest timestamp. */
  timestamp_submitted: Date | null
  timestamp_approved: Date | null
}

export interface ClothingDataDB extends ClothingData {
  _id: ObjectId
}

export enum UploadStatus {
  IN_PROGRESS,
  PARTIAL,
  IN_REVIEW,
  COMPLETED,
}

export class ClothingDataDB {
  static fromJson(json: Object) {
    const clothingData = Object.assign(new ClothingDataDB(), json)
    clothingData.collection_date = new Date(clothingData.collection_date)
    clothingData.timestamp_created = new Date(clothingData.timestamp_created)
    if (clothingData.timestamp_submitted !== null)
      clothingData.timestamp_submitted = new Date(
        clothingData.timestamp_submitted
      )
    if (clothingData.timestamp_approved !== null)
      clothingData.timestamp_approved = new Date(
        clothingData.timestamp_approved
      )
    clothingData.clothes = clothingData.clothes.map((clothingItem) =>
      ClothingItemWithPriceAndSku.fromJson(clothingItem)
    )
    clothingData.rejected_items = clothingData.rejected_items.map(
      (clothingItem) => ClothingRejectedItem.fromJson(clothingItem)
    )
    return clothingData
  }

  get id() {
    return this._id.$oid
  }

  get total_crediting_amount() {
    const total = this.clothes.reduce(
      (prev, item) => prev + item.crediting_amount,
      0
    )
    return total.toFixed(2)
  }

  get status() {
    if (!this.is_submitted) {
      return UploadStatus.IN_PROGRESS
    }
    if (this.is_partial_upload) {
      return UploadStatus.PARTIAL
    }
    if (!this.is_approved) {
      return UploadStatus.IN_REVIEW
    }
    return UploadStatus.COMPLETED
  }
}

export const constructSku = (number: string | number) => {
  return ENV.IS_PROD ? `OFF.${number}` : `OFF.TEST.${number}`
}

export interface GetAndIncrementNextSkuResponse {
  next_sku: number
}

export const constructBoxWhjId = (number: string | number) => {
  return ENV.IS_PROD ? `BOX.WHJ.${number}` : `BOX.WHJ.TEST.${number}`
}

export const constructRecordsSku = (number: string | number) => {
  return ENV.IS_PROD ? `ON.REC.${number}` : `ON.REC.TEST.${number}`
}

export const constructCdsSku = (number: string | number) => {
  return ENV.IS_PROD ? `ON.CD.${number}` : `ON.CD.TEST.${number}`
}

export const constructMarketplaceSku = (number: string | number) => {
  return ENV.IS_PROD ? `ON.MKT.${number}` : `ON.MKT.TEST.${number}`
}

export interface GetAndIncrementNextBoxWhjIdResponse {
  next_box_id: number
}

// TODO: Use friendly names (i.e. Luxury, Premium, Mid-priced, Low-priced, and Miscellaneous) here and in clothes-service models.
export enum Tier {
  TIER_0 = 0,
  TIER_1 = 1,
  TIER_2 = 2,
  TIER_3 = 3,
  TIER_4 = 4,
}

export const TierValues = [0, 1, 2, 3, 4]

export interface BrandTier {
  brand: string
  tier: Tier
}

export enum UserRole {
  ADMIN = 'Admin',
  SUPERVISOR = 'Supervisor',
  STAFF = 'Staff',
}
